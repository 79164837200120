export const WEBINARS_START_LOAD = 'WEBINARS_START_LOAD';
export const WEBINARS_FINISH_LOAD = 'WEBINARS_FINISH_LOAD';
export const WEBINARS_ERROR_LOAD = 'WEBINARS_ERROR_LOAD';

export const ONEWEBINAR_START_LOAD = 'ONEWEBINAR_START_LOAD';
export const ONEWEBINAR_FINISH_LOAD = 'ONEWEBINAR_FINISH_LOAD';
export const ONEWEBINAR_ERROR_LOAD = 'ONEWEBINAR_ERROR_LOAD';

export const WEBINARS_START_CREATE = 'WEBINARS_START_CREATE';
export const WEBINARS_FINISH_CREATE = 'WEBINARS_FINISH_CREATE';
export const WEBINARS_ERROR_CREATE = 'WEBINARS_ERROR_CREATE';

export const WEBINARS_START_UPDATE = 'WEBINARS_START_UPDATE';
export const WEBINARS_FINISH_UPDATE = 'WEBINARS_FINISH_UPDATE';
export const WEBINARS_ERROR_UPDATE = 'WEBINARS_ERROR_UPDATE';

export const WEBINARS_START_DELETE = 'WEBINARS_START_DELETE';
export const WEBINARS_FINISH_DELETE = 'WEBINARS_FINISH_DELETE';
export const WEBINARS_ERROR_DELETE = 'WEBINARS_ERROR_DELETE';

export const WEBINARSUBSCRIBERS_START_LOAD = 'WEBINARSUBSCRIBERS_START_LOAD';
export const WEBINARSUBSCRIBERS_FINISH_LOAD = 'WEBINARSUBSCRIBERS_FINISH_LOAD';
export const WEBINARSUBSCRIBERS_ERROR_LOAD = 'WEBINARSUBSCRIBERS_ERROR_LOAD';

export const WEBINARSUBSCRIBERS_START_UPDATE = 'WEBINARSUBSCRIBERS_START_UPDATE';
export const WEBINARSUBSCRIBERS_FINISH_UPDATE = 'WEBINARSUBSCRIBERS_FINISH_UPDATE';
export const WEBINARSUBSCRIBERS_ERROR_UPDATE = 'WEBINARSUBSCRIBERS_ERROR_UPDATE';