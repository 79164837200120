export const SUBMIT_START_LOAD = 'SUBMIT_START_LOAD';
export const SUBMIT_FINISH_LOAD = 'SUBMIT_FINISH_LOAD';
export const SUBMIT_ERROR_LOAD = 'SUBMIT_ERROR_LOAD';

export const SUBMITS_START_LOAD = 'SUBMITS_START_LOAD';
export const SUBMITS_FINISH_LOAD = 'SUBMITS_FINISH_LOAD';
export const SUBMITS_ERROR_LOAD = 'SUBMITS_ERROR_LOAD';

export const SUBMITS_START_ACCEPT = 'SUBMITS_START_ACCEPT';
export const SUBMITS_FINISH_ACCEPT = 'SUBMITS_FINISH_ACCEPT';
export const SUBMITS_ERROR_ACCEPT = 'SUBMITS_ERROR_ACCEPT';

export const SUBMITS_START_ACCEPT_GUEST = 'SUBMITS_START_ACCEPT_GUEST';
export const SUBMITS_FINISH_ACCEPT_GUEST = 'SUBMITS_FINISH_ACCEPT_GUEST';
export const SUBMITS_ERROR_ACCEPT_GUEST = 'SUBMITS_ERROR_ACCEPT_GUEST';

export const SUBMITS_START_DECLINE = 'SUBMITS_START_DECLINE';
export const SUBMITS_FINISH_DECLINE = 'SUBMITS_FINISH_DECLINE';
export const SUBMITS_ERROR_DECLINE = 'SUBMITS_ERROR_DECLINE';

export const SUBMITS_START_SEND_CONFIRM = 'SUBMITS_START_SEND_CONFIRM';
export const SUBMITS_FINISH_SEND_CONFIRM = 'SUBMITS_FINISH_SEND_CONFIRM';
export const SUBMITS_ERROR_SEND_CONFIRM = 'SUBMITS_ERROR_SEND_CONFIRM';