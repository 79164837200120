export const STAGES_LOAD_START = "STAGES_LOAD_START";
export const STAGES_LOAD_FINISH = "STAGES_LOAD_FINISH";
export const STAGES_LOAD_ERROR = "STAGES_LOAD_ERROR";

export const STAGES_UPDATE_START = "STAGES_UPDATE_START";
export const STAGES_UPDATE_FINISH = "STAGES_UPDATE_FINISH";
export const STAGES_UPDATE_ERROR = "STAGES_UPDATE_ERROR";

export const STAGE_LOAD_START = "STAGE_LOAD_START";
export const STAGE_LOAD_FINISH = "STAGE_LOAD_FINISH";
export const STAGE_LOAD_ERROR = "STAGE_LOAD_ERROR";

export const STAGE_CREATE_START = "STAGE_CREATE_START";
export const STAGE_CREATE_FINISH = "STAGE_CREATE_FINISH";
export const STAGE_CREATE_ERROR = "STAGE_CREATE_ERROR";

export const STAGE_DELETE_START = "STAGE_DELETE_START";
export const STAGE_DELETE_FINISH = "STAGE_DELETE_FINISH";
export const STAGE_DELETE_ERROR = "STAGE_DELETE_ERROR";

export const STAGE_COPY_START = "STAGE_COPY_START";
export const STAGE_COPY_FINISH = "STAGE_COPY_FINISH";
export const STAGE_COPY_ERROR = "STAGE_COPY_ERROR";