import { MergeObjects } from "./../core/library/objects";
import MediaSources from "./media_sources";
import * as Projects from "./projects";
import { parseUrlParams } from "../core/library/url";
import SubmitStatuses from "./statuses";

const PROJECT = process.env.PROJECT || "";

let Config = {
  LOCALE: "ru",
  PROJECT_PREFIX: "test",
  // project config
  PROJECT: {
    // project name
    NAME: PROJECT,
    // project default layout
    LAYOUT: "default",
    // project api path
    API: `http://localhost:9000/api/v1`,
    DOMAIN: `localhost:3000`,
  },

  // oauth configuration
  OAUTH: {
    // url for oauth domain
    URL: `https://auth.rt.com`,
    // current project domain
    PROJECT_DOMAIN: `localhost:3000`,
    // current project url path
    PROJECT_PATH: `http://localhost:3000`,
    // path for oauth api
    API: `https://auth.rt.com/auth`,
    // path for oauth users
    OAUTH_USER_PATH: `https://auth.rt.com/users/`,
    // prefix for authorization tokens
    PREFIX: "oauth_",
    AUTH_HEADER: "X-Access-Token",
  },
  // default date format
  DATE_FORMAT: "DD-MM-YYYY HH:mm",
  // configuration for slug plugin
  SLUG: {
    delimiter: "-",
    prefix: "",
  },

  // configuration for media
  MEDIA: {
    // path for cdn content
    CDN: {
      IMAGES: "http://localhost:9000/images",
      VIDEO: "http://localhost:9000/videos",
      AUDIO: "http://localhost:9000/audio",
      AVATARS: "http://localhost:9000/avatars",
      ATTACH: "http://localhost:9000/app",
      DOCUMENTS: "http://localhost:9000/documents",
    },
    // available copyright sources
    SOURCES: MediaSources,
    // available upload types
    UPLOAD_AVAILABLE: ["jpg", "gif", "png", "jpeg", "mp4", "mp3"],
    // available embedded sources
    EMBEDDED_SOURCES: ["youtube.com", "facebook.com", "youtu.be", "rutube.ru"],
    EMEDDED_PREPARE: {
      "youtu.be": (path) => {
        let params = path.split("/");
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${
          params[params.length - 1]
        }" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      },
      "youtube.com": (path) => {
        let p = parseUrlParams(path);
        if (!p.v) return ``;
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      },
      "facebook.com": (url) => {
        return `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
          url
        )}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`;
      },
    },
  },

  EDITOR: {
    KEY: "clzzivqs4p1bb1eoei67nmbzwwzio5uv0w69ew52cc8ex5s7",
    SETTINGS: {
      language: "ru",
    },
  },

  MEDIA_DATABASE_TYPES: ["Image", "Video", "ExternalVideo", "Code"],
  MEDIA_AVAILABLE_TYPES: ".jpg,.gif,.png,.jpeg,.mp4,.mp3",
  MEDIA_AVAILABLE_SOURCES: ["youtube", "vimeo", "facebook", "rutube.ru"],
  SUBMITS_STATUSES: SubmitStatuses,
  HOMEWORK_STATUSES: ["created", "rejected", "checked"],
  UPLOADED_FILES_MIME: [
    "audio/m4a",
    "audio/mpeg",
    "audio/mp3",
    "audio/mp4",
    "audio/x-m4a",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/pjpeg",
    "application/pdf",
    "application/zip",
    "application/gzip",
    "video/mp4",
    "video/quicktime",
    "video/mov",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/xls",
    "application/msexcel",
    "application/vnd.ms-excel",
  ],
};

if (Projects[PROJECT]) {
  Config = MergeObjects(Config, Projects[PROJECT]);
}

window.APP_CONFIG = {
  PROJECT: Config.PROJECT,
  MEDIA: Config.MEDIA,
};

export default Config;
