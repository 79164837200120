export const START_LOAD_MAILILINGLIST = 'START_LOAD_MAILILINGLIST';
export const ERROR_LOAD_MAILILINGLIST = 'ERROR_LOAD_MAILILINGLIST';
export const FINISH_LOAD_MAILILINGLIST = 'FINISH_LOAD_MAILILINGLIST';

export const START_SEND_MAIL = 'START_SEND_MAIL';
export const ERROR_SEND_MAIL = 'ERROR_SEND_MAIL';
export const FINISH_SEND_MAIL = 'FINISH_SEND_MAIL';

export const START_LOAD_WEBINARNOTIFICATIONS = 'START_LOAD_WEBINARNOTIFICATIONS';
export const ERROR_LOAD_WEBINARNOTIFICATIONS = 'ERROR_LOAD_WEBINARNOTIFICATIONS';
export const FINISH_LOAD_WEBINARNOTIFICATIONS = 'FINISH_LOAD_WEBINARNOTIFICATIONS';

export const START_SEND_WEBINARNOTIFICATIONS = 'START_SEND_WEBINARNOTIFICATIONS';
export const ERROR_SEND_WEBINARNOTIFICATIONS = 'ERROR_SEND_WEBINARNOTIFICATIONS';
export const FINISH_SEND_WEBINARNOTIFICATIONS = 'FINISH_SEND_WEBINARNOTIFICATIONS';

export const START_UPDATE_WEBINARNOTIFICATIONS = 'START_UPDATE_WEBINARNOTIFICATIONS';
export const ERROR_UPDATE_WEBINARNOTIFICATIONS = 'ERROR_UPDATE_WEBINARNOTIFICATIONS';
export const FINISH_UPDATE_WEBINARNOTIFICATIONS = 'FINISH_UPDATE_WEBINARNOTIFICATIONS';

export const START_CREATE_WEBINARNOTIFICATIONS = 'START_CREATE_WEBINARNOTIFICATIONS';
export const ERROR_CREATE_WEBINARNOTIFICATIONS = 'ERROR_CREATE_WEBINARNOTIFICATIONS';
export const FINISH_CREATE_WEBINARNOTIFICATIONS = 'FINISH_CREATE_WEBINARNOTIFICATIONS';

export const START_PREWIEW_WEBINARNOTIFICATIONS = 'START_PREWIEW_WEBINARNOTIFICATIONS';
export const ERROR_PREWIEW_WEBINARNOTIFICATIONS = 'ERROR_PREWIEW_WEBINARNOTIFICATIONS';
export const FINISH_PREWIEW_WEBINARNOTIFICATIONS = 'FINISH_PREWIEW_WEBINARNOTIFICATIONS';