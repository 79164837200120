export function generateParamsFromObject(data = {}, name = false) {
  let params = [];
  for (let i in data) {
    let keyName = name === false ? `${i}` : `${name}[${i}]`;
    if (data[i] !== undefined) {
      if (typeof data[i] === "object") {
        let value = data[i];
        if (Array.isArray(value)) {
          value.forEach((v) => {
            params.push(`${keyName}=${encodeURIComponent(v)}`);
          });
        } else {
          params.push(generateParamsFromObject(value, i));
        }
      } else {
        params.push(`${keyName}=${encodeURIComponent(data[i])}`);
      }
    }
  }
  return params.length > 0 ? params.join("&") : "";
}

export function parseUrlParams(request = "") {
  const url = new URL(request || window.location.href);

  let result = {};
  url.searchParams.forEach((value, key) => {
    result[key] = value;
  });

  // console.debug("params:", result);
  return result;
}

export function prepareParams(keys = []) {
  let params = {};
  let urlParams = parseUrlParams();
  keys.map((i) => {
    if (urlParams[i] !== undefined) {
      let vals = urlParams[i];
      if (Array.isArray(vals)) {
        vals = vals.split(",");
      }
      params[i] = vals;
    }
    return i;
  });
  return params;
}

export function setURLSearchParam(params = {}) {
  const url = new URL(window.location.href);
  url.searchParams.forEach((key, val) => {
    if (!val) {
      url.searchParams.delete(key);
    }
  });
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      let value = params[key];

      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
  });

  window.history.pushState({ path: url.href }, "", url.href);
}
