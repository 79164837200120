export const LESSONS_LOAD_START = 'LESSONS_LOAD_START';
export const LESSONS_LOAD_FINISH = 'LESSONS_LOAD_FINISH';
export const LESSONS_LOAD_ERROR = 'LESSONS_LOAD_ERROR';

export const LESSONS_UPDATE_START = 'LESSONS_UPDATE_START';
export const LESSONS_UPDATE_FINISH = 'LESSONS_UPDATE_FINISH';
export const LESSONS_UPDATE_ERROR = 'LESSONS_UPDATE_ERROR';

export const LESSON_LOAD_START = 'LESSON_LOAD_START';
export const LESSON_LOAD_FINISH = 'LESSON_LOAD_FINISH';
export const LESSON_LOAD_ERROR = 'LESSON_LOAD_ERROR';

export const LESSON_CREATE_START = 'LESSON_CREATE_START';
export const LESSON_CREATE_FINISH = 'LESSON_CREATE_FINISH';
export const LESSON_CREATE_ERROR = 'LESSON_CREATE_ERROR';

export const LESSON_DELETE_START = 'LESSON_DELETE_START';
export const LESSON_DELETE_FINISH = 'LESSON_DELETE_FINISH'; 
export const LESSON_DELETE_ERROR = 'LESSON_DELETE_ERROR';

export const LESSON_COPY_START = 'LESSON_COPY_START';
export const LESSON_COPY_FINISH = 'LESSON_COPY_FINISH';
export const LESSON_COPY_ERROR = 'LESSON_COPY_ERROR';