export default (store) => (next) => (action) => {
  if (action.type === "PROMISE") {
    const [START_ACTION, FINISH_ACTION, ERROR_ACTION] = action.actions;
    store.dispatch({ type: START_ACTION });
    action.promise
      .then((res) => {
        store.dispatch({
          type: FINISH_ACTION,
          data: res,
        });
      })
      .catch((error) => {
        store.dispatch({
          type: ERROR_ACTION,
          data: error,
        });
      });
  } else {
    return next(action);
  }
};
