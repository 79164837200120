import React from 'react';
import {Layout} from 'antd';
import asyncComponent from './../core/components/async';
import Config from './../config';
import { AppConsumer } from './../context/app';


const Menu = asyncComponent(() => {
    return import(`./../core/components/menu.js`);
});

const Header = asyncComponent(() => {
    return import(`./../core/components/header/index.js`);
});

const Router = asyncComponent(() => {
    return import('./../core/router/index.js');
});


function inArray(stack, need) {
    if (!Array.isArray(stack)) return false;
    for (let i = 0; i < stack.length; i++) {
        if (stack[i] === need) {
            return true;
        }
    }
    return false;
} 

export class DefaultLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    onCollapse = collapsed => {
        this.setState({collapsed});
    };


    render() {
        const MItems = inArray(Config.ExcludeEmails, window.userEmail) ? Config.MENU_NAUCH : Config.MENU;
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Layout.Sider
                    collapsible
                    className={`sidebar`}
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                >
                    <div className={`logo`}/>
                    <Menu items={MItems || []} email={this.props.email}/>
                </Layout.Sider>
                <Layout>
                    <Layout.Header className={`app_header`}>
                        <Header consumer={AppConsumer}/>
                    </Layout.Header>
                    <Router />
                </Layout>
            </Layout>
        );
    }
}

export default DefaultLayout;
