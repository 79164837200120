export const COURSES_LOAD_START = "COURSES_LOAD_START";
export const COURSES_LOAD_FINISH = "COURSES_LOAD_FINISH";
export const COURSES_LOAD_ERROR = "COURSES_LOAD_ERROR";

export const COURSES_UPDATE_START = "COURSES_UPDATE_START";
export const COURSES_UPDATE_FINISH = "COURSES_UPDATE_FINISH";
export const COURSES_UPDATE_ERROR = "COURSES_UPDATE_ERROR";

export const COURSE_LOAD_START = "COURSE_LOAD_START";
export const COURSE_LOAD_FINISH = "COURSE_LOAD_FINISH";
export const COURSE_LOAD_ERROR = "COURSE_LOAD_ERROR";

export const COURSE_CREATE_START = "COURSE_CREATE_START";
export const COURSE_CREATE_FINISH = "COURSE_CREATE_FINISH";
export const COURSE_CREATE_ERROR = "COURSE_CREATE_ERROR";

export const COURSE_COPY_START = "COURSE_COPY_START";
export const COURSE_COPY_FINISH = "COURSE_COPY_FINISH";
export const COURSE_COPY_ERROR = "COURSE_COPY_ERROR";
